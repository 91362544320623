import { FC, RefObject } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Icon } from '@common/atoms/Icon';
import { Link } from '@common/atoms/Link';
import { News, PlatformID, Tag } from '@common/clients/api';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { useContextData } from '@common/useContextData';
import {
    DateTimeUtil,
    Format,
    formatDuration,
    formatNumericDuration,
    Perspective,
} from '@common/utils/DateTimeUtil';
import { TagElement } from '@web/atoms/TagElement';
import { WebpImage } from '@web/atoms/WebpImage';
import { PlatformToggle } from '@web/molecules/PlatformToggle';

import { Flag } from '../Flag';
import { DisplayType } from '../NewsList';
import { ViBlock } from '../ViBlock';

import styles from './NewsListItem.module.scss';

interface Props {
    item: News;
    displayType?: DisplayType;
    isDossier?: boolean;
    firstElementRef?: RefObject<HTMLLIElement>;
    tag?: Tag;
}

export const NewsListItem: FC<Props> = ({ item, displayType, isDossier, firstElementRef, tag }) => {
    const { platform } = useContextData();

    const __datetime = useTranslation('datetime').t;
    const date: Date = new Date(item.newsDate);

    // Add flag and date format before title

    // Set path to article
    let path: string = '';
    if (item.host && item.path) {
        path = item.host + item.path;
    }

    const classes = [styles.NewsItem];
    const dataItems: { [key: string]: string } = {};
    if (displayType) classes.push(styles[displayType]);
    if (isDossier) classes.push(styles.dossier);
    if (item.category?.functionality) classes.push(`${item.category?.functionality}-article`);

    if (item.category?.functionality === 'betting') {
        classes.push('ndm-betting-integration-tracker');
        dataItems['data-tracker-name'] = 'advertorialNewsList';
    }

    const isProArticle = item.category?.functionality === 'pro';
    if (isProArticle) {
        classes.push(styles.pro);
    }

    const isMini = displayType === DisplayType.compact || displayType === DisplayType.list;
    const isBlockType = displayType === DisplayType.blocks || displayType === DisplayType.carrousel;
    if (isBlockType) classes.push(styles[`block-type`]);

    const isBold = item?.titleIsBold;
    const imageSize = isMini ? WebpWidthEnum.WIDTH_110 : WebpWidthEnum.WIDTH_225;

    return (
        <li className={classes.join(' ')} ref={firstElementRef} {...dataItems}>
            {platform.id === PlatformID.VI && isBlockType ? (
                <ViBlock item={item} />
            ) : (
                <>
                    {displayType === DisplayType.compact ? (
                        <PlatformToggle exclude={PlatformID.VI}>
                            {isDossier ? (
                                <span className={styles.radio} />
                            ) : (
                                <>
                                    <PlatformToggle include={[PlatformID.VP, PlatformID.VN]}>
                                        <Flag
                                            displayType={displayType}
                                            tag={item.tags?.at(0)}
                                            platform={platform.id}
                                        />
                                    </PlatformToggle>
                                    <span className={styles.time} suppressHydrationWarning={true}>
                                        {item?.newsVideo ? <Icon.play /> : null}
                                        {DateTimeUtil.format(date, Format.TIME_NUMERIC)}
                                    </span>
                                </>
                            )}
                        </PlatformToggle>
                    ) : null}
                    {displayType !== DisplayType.compact ? (
                        <Link href={path}>
                            <div className={styles['play-video']}>
                                {item.category?.isMedia && !isMini ? (
                                    <>
                                        <PlatformToggle exclude={PlatformID.VI}>
                                            <span className={'play-button'}>
                                                <span className="icon"></span>
                                            </span>
                                        </PlatformToggle>
                                        {item?.videofeedItem?.duration ? (
                                            <span className={styles.time}>
                                                {formatNumericDuration(item.videofeedItem.duration)}
                                            </span>
                                        ) : null}
                                    </>
                                ) : null}
                                <WebpImage
                                    defaultSize={imageSize}
                                    className={styles.image}
                                    src={item.image || ''}
                                    alt={''}
                                    ratio={110 / 60}
                                />
                            </div>
                        </Link>
                    ) : null}
                    {!isMini ? (
                        <div className={styles['tag-container']}>
                            <p>{item.category?.name}</p>
                        </div>
                    ) : null}
                    <div className={isDossier ? styles.content + ' content' : styles.content}>
                        <Link href={path}>
                            <h4 className={isBold ? styles['extra-bold'] : ''}>
                                <div className={styles['title-wrapper']}>
                                    <PlatformToggle exclude={PlatformID.VI}>
                                        <TagElement item={item} displayType={displayType} tag={tag} />
                                    </PlatformToggle>
                                    <PlatformToggle include={PlatformID.VI}>
                                        <>
                                            {item.category?.functionality === 'pro' ? (
                                                <span className={styles['pro-tag']}>
                                                    <Icon.pro /> <TagElement item={item} />
                                                </span>
                                            ) : null}
                                        </>
                                    </PlatformToggle>
                                    <p className={styles.title}>{item.newsTitle}</p>
                                </div>
                            </h4>
                        </Link>
                        <PlatformToggle exclude={PlatformID.VI}>
                            <div className={styles.meta}>
                                <span className={styles['long-date']} suppressHydrationWarning={true}>
                                    {formatDuration({
                                        date,
                                        __translate: __datetime,
                                        perspective: Perspective.IN_AGO,
                                    })}
                                </span>
                                {item.commentCount ? (
                                    <Link href={path + '#comments_anchor'}>
                                        <div className={styles.comments}>
                                            <Icon.comment />
                                            <span className={styles['comment-count']}>
                                                {item.commentCount}
                                            </span>
                                        </div>
                                    </Link>
                                ) : null}
                            </div>
                        </PlatformToggle>
                    </div>
                </>
            )}
        </li>
    );
};
